<template>
	<Popup name="voie"
	text="<h3>Voie ferrée</h3><br/><p>Environ 60 trains transportant plusieurs milliers de voyageurs et des marchandises circulent quotidiennement sur cet axe. 14 d’entre eux s’arrêtent à la halte ferroviaire de Donges. Le projet de contournement ferroviaire des sites industriels de Donges consiste à créer une déviation de la voie ferrée actuelle, d’une longueur de 4km au nord de la RD100, et à déplacer la halte existante à proximité du centre-ville de Donges.</p>"
	video="static/video/sncf.mp4"
	sticker="static/logo-total.jpg">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/voie-ferree_1_thyzk2_c_scale_w_350.jpg 350w,
		img/popup/voie-ferree_1_thyzk2_c_scale_w_773.jpg 773w,
		img/popup/voie-ferree_1_thyzk2_c_scale_w_970.jpg 970w"
		data-src="img/popup/voie-ferree_1_thyzk2_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Popup>
</template>

<script>
	export default {
		components: {
			Popup: () => import('@/components/Popup.vue'),
		},
	}
</script>
